import { PageDocumentSlugOnly, PageTypeToPathFunction } from './types/types';
import { urlJoin } from './utils/utils';

export const AUTHOR_NAME = 'Digital Spiders';
export const COPYRIGHT_DEFAULT_YEAR = 2024;

export const DEFAULT_LANG = 'en';
export const ALL_LANGS = [DEFAULT_LANG] as const;

export const DEFAULT_SITE_DOMAIN = 'digitalspiders.io';
export const DEFAULT_SITE_BASE_URL = 'https://www.digitalspiders.io';

// WARNING When updating these three constants update in site constants too
// WARNING When updating these three constants update in site create_redirects_file_from_cms too
export const ARTICLES_PREFIX_URL = 'articles';
export const PROJECTS_PREFIX_URL = 'case-studies';

export const PAGE_DOCUMENT_TYPES = ['page', 'blogPage', 'article'] as const;

export const PAGE_TYPE_TO_PATH_FUNCTION = {
  page: (pageDoc: PageDocumentSlugOnly) => urlJoin(pageDoc.slug.current),
  blogPage: (pageDoc: PageDocumentSlugOnly) => urlJoin(ARTICLES_PREFIX_URL),
  article: (pageDoc: PageDocumentSlugOnly) => urlJoin(ARTICLES_PREFIX_URL, pageDoc.slug.current),
} as const satisfies PageTypeToPathFunction;

export const VIDEO_TYPES_MAP = {
  tiktok: {
    type: 'tiktok',
    title: 'TikTok',
    formatRegex: /^https:\/\/(www\.)?tiktok\.com\//,
  },
  instagram: {
    type: 'instagram',
    title: 'Instagram',
    formatRegex: /^https:\/\/(www\.)?(instagram\.com|instagr\.am|instagr\.com)\//,
  },
  youtube: {
    type: 'youtube',
    title: 'Youtube',
    formatRegex: /^https:\/\/(www\.)?(youtube\.com|youtu\.be)\//,
  },
} as const;

/**
 *
 * Below here are constants we normally don't need to change or check
 *
 */

export const SITE_DOMAIN =
  process.env.NODE_ENV === 'development'
    ? 'localhost:8000'
    : process.env.GATSBY_SITE_DOMAIN || DEFAULT_SITE_DOMAIN;

export const SITE_BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8000'
    : process.env.GATSBY_SITE_BASE_URL || DEFAULT_SITE_BASE_URL;

export const MAX_OG_DESCRIPTION_LENGTH = 300;

export const GOOGLE_OPTIMIZE_ID = process.env.GATSBY_GOOGLE_OPTIMIZE_ID;
export const SHOULD_RUN_GOOGLE_OPTIMIZE =
  process.env.NODE_ENV === `production` && !!GOOGLE_OPTIMIZE_ID;

export const BRAND_GRADIENT_HSL_COLOR_STOPS = [
  { color: { a: 194, b: 100, c: 50 }, position: 0.0 },
  { color: { a: 274, b: 100, c: 47 }, position: 0.3 },
  { color: { a: 320, b: 100, c: 45 }, position: 0.6 },
  { color: { a: 4, b: 95, c: 56 }, position: 1 },
];

export const LIST_ITEM_HSL_COLOR_STOPS = [
  { color: { a: 354, b: 96, c: 51 }, position: 0.0 },
  { color: { a: 336, b: 98, c: 50 }, position: 0.2 },
  { color: { a: 312, b: 100, c: 45 }, position: 0.4 },
  { color: { a: 272, b: 100, c: 46 }, position: 0.6 },
  { color: { a: 232, b: 100, c: 59 }, position: 0.8 },
  { color: { a: 204, b: 100, c: 50 }, position: 1.0 },
];
